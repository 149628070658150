import React, { useState, useRef } from 'react'
import { useForm, Controller, useWatch } from 'react-hook-form'
import { isEmpty } from 'lodash'
import { isPossiblePhoneNumber } from 'libphonenumber-js';
import { useParams, useNavigate, Link } from 'react-router-dom'
import * as Toast from 'components/Toast'
import { Input } from 'components/Input'
import { Button } from 'components/Button'
import { Label } from 'components/Label'
import { Dropdown } from 'components/Dropdown'
import { usePatients, useClinics, useAuth, useHasCompletedSessions, useTitle } from 'hooks';
import { ClinicDetail, CreatePatientDTO, PatientOutputDTO, PatientStatus, UpdatePatientDTO } from 'types';
import { ReactComponent as ArrowLeft } from 'assets/icons/left-caret.svg'
import { states } from 'constants/State'
import { races } from 'constants/Race'
import { genders } from 'constants/Gender'
import { patients as PatientActions } from 'data'
import { DEFAULT_AREA_CODE, InputPhone } from 'components/Input/InputPhone';
import { NextAppointmentDetails } from 'pages/private/Appointments/NextAppointmentDetails';
import { CancelledAppointments } from "../Appointments/CancelledAppointments";
import { PreviousAppointments } from '../Appointments/PreviousAppointments';
import { InputDate } from 'components/Input/InputDate';
import { displayDate, validateDOB } from 'lib/day';
import { PatientStatusOptions } from './constants';
import { isEmail } from 'lib/utils';
import { Autocomplete } from 'components/Autocomplete';
import clsx from 'clsx';
import { useFieldDictionary } from 'hooks/useFields';
import { useCheckForExistingPatient } from 'hooks/useCheckForExistingPatient';

const defaultPatient: Partial<CreatePatientDTO> = {
  email: '',
  firstName: '',
  lastName: '',
  phone: '',
  dob: '',
  race: '',
  gender: '',
  address: {
    street: '',
    city: '',
    state: '',
    zip: '',
  },
  clinicIds: [],
  emergencyContact: {
    firstName: '',
    lastName: '',
    phone: '',
    relationship: ''
  },
  status: PatientStatus.Active,
  statusChangeReason: ''
}
interface Props {
  isNewPatient?: boolean;
}

interface PatientFormProps {
  patient: PatientOutputDTO
  isEditMode?: boolean
  onSave?: () => void
  isReadOnly?: boolean
}

const sanitizeInputData = (data: CreatePatientDTO, dirtyFields: any) => {
  return {
    ...(dirtyFields.email && data.email && { email: data.email }),
    ...(dirtyFields.firstName && { firstName: data.firstName }),
    ...(dirtyFields.lastName && { lastName: data.lastName }),
    ...(dirtyFields.phone && { phone: data.phone }),
    ...(dirtyFields.dob && { dob: data.dob }),
    ...(dirtyFields.race && { race: data.race }),
    ...(dirtyFields.gender && { gender: data.gender }),
    ...(dirtyFields.address && { address: data.address }),
    ...(dirtyFields.emergencyContact && { emergencyContact: data.emergencyContact }),
    ...(dirtyFields.clinicIds && { clinicIds: data.clinicIds }),
    ...(dirtyFields.status && { status: data.status }),
    ...(dirtyFields.statusChangeReason && { statusChangeReason: data.statusChangeReason }),
  }
}

const noop = () => { }
export const PatientForm: React.FC<PatientFormProps> = ({
  patient,
  isEditMode = false,
  onSave = noop,
  isReadOnly
}) => {
  const { fieldLabel, fieldValues } = useFieldDictionary()
  const [loading, setLoading] = useState(false)
  const { clinicId } = useAuth()
  const { data: paginatedData } = useClinics({})
  const hasPrevSessions = useHasCompletedSessions(patient?.id || '')
  const navigate = useNavigate()
  const prevSessionsSectionRef = useRef<HTMLDivElement>(null)
  const auth = useAuth()
  const isLocalEMREnabled = auth.enableLocalEmr
  const [selectedTab, setSelectedTab] = useState<'cancelled' | 'previous'>('previous')

  const clinics = paginatedData?.data || []

  const {
    register,
    reset,
    handleSubmit,
    getValues,
    formState: { errors, isDirty, dirtyFields },
    control,
    watch,
    setValue,
  } = useForm({
    defaultValues: patient
  })

  const [firstName, lastName, dob] = useWatch({ control, name: ['firstName', 'lastName', 'dob']})
  const [existingPatient] = useCheckForExistingPatient(
    { firstName, lastName, dob, clinicId },
    Boolean(errors.firstName || errors.lastName || errors.dob || isEditMode || isReadOnly)
  )
  const patientStatus = watch('status')

  const onBack = () => {
    navigate(-1)
  }

  const goToPrevSessions = () => {
    if (!prevSessionsSectionRef.current) return

    prevSessionsSectionRef.current.scrollIntoView({
      behavior: 'smooth'
    })
  }

  const onSubmit = async (data: CreatePatientDTO | UpdatePatientDTO) => {
    try {
      setLoading(true)

      if (!isEditMode) {
        const { payload } = await PatientActions.create({
          ...sanitizeInputData(data, dirtyFields),
          clinicId: auth.clinicId,
          clinicIds: data.clinicIds,
          isLocalEMR: isLocalEMREnabled,
          isReadOnly: !isLocalEMREnabled,
          status: data.status || PatientStatus.Active,
        })
        reset(patient)
        onSave()

        navigate(`/clinics/${clinicId}/patients/${payload.patientId}`, { replace: true })
      }

      if (isEditMode && patient.id) {
        await PatientActions.update({
          id: patient.id,
          clinicId: auth.clinicId,
          ...sanitizeInputData(data, dirtyFields),
        })
        reset(data)
        onSave()
      }

      Toast.success('Patient saved successfully')
    } catch (err: any) {
      Toast.error(err?.message ?? 'Patient Update/Creation Failed!')
    } finally {
      setLoading(false)
    }
  }

  const validatePhone = (value: string | undefined) => {
    try {
      const cleanedValue = value?.trim()
      const phoneValue = `${cleanedValue?.startsWith(DEFAULT_AREA_CODE) ? '' : DEFAULT_AREA_CODE} ${cleanedValue}`.trim()
      if (!phoneValue) {
        return 'Phone number is required';
      }
      const phoneNumber = isPossiblePhoneNumber((phoneValue || ''));
      if (!phoneNumber) {
        return 'Invalid phone number';
      }
      return true;
    } catch (error) {
      return 'Invalid phone number';
    }
  }

  if (Object.keys(errors).length > 0) {
    console.log('form errors => ', errors)
  }

  return (
    <div className='w-full'>
      {/* Top Bar */}
      <div className='flex px-6 py-4 bg-white sticky z-50 top-14 justify-between align-middle self-stretch items-center shadow-md border-2 border-gray-50 my-6 rounded-lg'>
        <div className='flex space-x-4 items-center'>
          <div>
            <Button type="button" className='bg-white w-9 min-h-9 h-[36px] p-0' onClick={onBack}>
              <ArrowLeft />
            </Button>
          </div>
          <span className='text-2xl font-medium mr-8'>
            {patient.firstName ? `${patient?.firstName} ${patient?.lastName}` : `Create New Patient`}
          </span>
        </div>

        <div className='flex gap-2 items-center'>
          {existingPatient && (
            <div className='flex gap-2 border px-4 bg-red-200 shadow-sm border-red-400 rounded-lg'>
              <div>Patient with same properties already exists: </div>
              <div className='flex gap-2'>
                <span className='underline font-bold'>
                  <Link to={`/clinics/${clinicId}/patients/${existingPatient.id}`}>
                    {existingPatient.firstName} {existingPatient.lastName} - {displayDate(existingPatient.dob as string)}
                  </Link>
                </span>
              </div>
            </div>
          )}

          {isEditMode && hasPrevSessions && (
            <Button
              className='min-h-8 h-8 font-light'
              onClick={goToPrevSessions}
            >
              Previous Sessions
            </Button>
          )}

          <Button
            disabled={!isDirty || loading || isReadOnly}
            className={clsx('min-h-8 h-8 bg-secondary font-light', {
              'hidden': isReadOnly
            })}
            type='button'
            onClick={handleSubmit(onSubmit, Toast.formErrors)}
          >
            Save Patient
          </Button>
        </div>
      </div>

      <div className='space-y-6 pb-6'>
        {/* Next Appointments */}
        {isEditMode && (
          <div className='relative'>
            <NextAppointmentDetails
              isReadOnly={isReadOnly || patient.status === PatientStatus.Inactive}
              patientId={patient.id || ''}
            />
          </div>
        )}

        {/* Content */}
        <form
          className='w-full space-y-4 overflow-hidden relative'
          onSubmit={handleSubmit(onSubmit, Toast.formErrors)}
        >
          <div className='grid grid-cols-1 md:grid-cols-2 gap-6'>
            {/* More Information */}
            <div className='px-6 py-4 shadow-md border-2 border-gray-50 rounded-lg'>
              <h2 className='text-xl mb-8'>Main Information</h2>

              <table className='w-full'>
                <tbody>

                  {/* Email */}
                  <tr>
                    <td className='py-3 align-top' width="30%">
                      <Label
                        name="email"
                      >
                        Email
                      </Label>
                    </td>
                    <td>
                      <Input
                        inline={true}
                        type='email'
                        placeholder='Enter email'
                        autoComplete='email'
                        readOnly={isReadOnly}
                        {...register('email', {
                          validate: value => {
                            if (value && value?.trim().length > 0 && !isEmail(value)) {
                              return 'Invalid Email'
                            }

                            return true
                          },
                        })}
                        error={errors?.email?.message}
                      />
                    </td>
                  </tr>

                  {/* First Name */}
                  <tr>
                    <td className='py-3 align-top'>
                      <Label
                        name="firstName"
                      >
                        First Name*
                      </Label>
                    </td>
                    <td>
                      <Input
                        inline={true}
                        type='text'
                        placeholder='Enter First Name'
                        readOnly={isReadOnly}
                        autoComplete='first-name'
                        {...register('firstName', {
                          required: 'First Name is required'
                        })}
                        error={errors?.firstName?.message}
                      />

                    </td>
                  </tr>

                  {/* Last Name */}
                  <tr>
                    <td className='py-3 align-top'>
                      <Label
                        name="lastName"
                      >
                        Last Name*
                      </Label>
                    </td>
                    <td>
                      <Input
                        inline={true}
                        type='text'
                        placeholder='Enter Last Name'
                        readOnly={isReadOnly}
                        autoComplete='last-name'
                        {...register('lastName', {
                          required: 'Last Name is required'
                        })}
                        error={errors?.lastName?.message}
                      />
                    </td>
                  </tr>

                  {/* Phone */}
                  <tr>
                    <td className='py-3 align-top'>
                      <Label
                        name="phone"
                      >
                        Phone*
                      </Label>
                    </td>
                    <td>
                      <Controller
                        name='phone'
                        control={control}
                        rules={{
                          validate: validatePhone
                        }}
                        render={({ field }) => (
                          <InputPhone
                            inline={true}
                            type='text'
                            placeholder='Enter phone number (e.g., 123 456 7890)'
                            autoComplete='phone'
                            readOnly={isReadOnly}
                            value={getValues().phone}
                            onChange={(value: string) => {
                              field.onChange(value)
                            }}
                            error={errors?.phone?.message}
                          />
                        )}
                      />
                    </td>
                  </tr>

                  {/* DOB */}
                  <tr>
                    <td className='py-3 align-top'>
                      <Label
                        name="dob"
                      >
                        DOB*
                      </Label>
                    </td>
                    <td>
                      <InputDate
                        auto={false}
                        inline={true}
                        placeholder='Enter DOB'
                        autoComplete='dob'
                        readOnly={isReadOnly}
                        {...register('dob', {
                          validate: value => {
                            return validateDOB(value)
                          },
                          required: 'DOB is required'
                        })}
                        error={errors?.dob?.message}
                      />
                    </td>
                  </tr>

                  {/* Status */}
                  <tr>
                    <td className='py-3 align-top'>
                      <Label
                        name="status"
                      >
                        Status
                      </Label>
                    </td>
                    <td>
                      <Controller
                        name='status'
                        control={control}
                        render={({ field }) => (
                          <Dropdown
                            required
                            value={field.value}
                            className='h-12'
                            background='white'
                            placeholder='Choose Status'
                            readOnly={isReadOnly}
                            onChange={(event) => {
                              const value = event.target.value
                              field.onChange(event.target.value)

                              if (value === PatientStatus.Active)
                                setValue('statusChangeReason', '')
                            }}
                            options={PatientStatusOptions}
                          />
                        )}
                      />
                    </td>
                  </tr>

                  {/* Status Reason */}
                  {patientStatus === PatientStatus.Inactive && (
                    <tr>
                      <td className='py-3 align-top'>
                        <Label
                          name="statusChangeReason"
                        >
                          Status Change Reason*
                        </Label>
                      </td>
                      <td>
                        <Input
                          inline={true}
                          type='text'
                          placeholder='Enter a reason'
                          readOnly={isReadOnly}
                          {...register('statusChangeReason', {
                            required: 'Status change reason is required'
                          })}
                          error={errors?.statusChangeReason?.message}
                        />
                      </td>
                    </tr>
                  )}

                </tbody>
              </table>

            </div>

            {/* Address */}
            <div className='px-6 py-4 shadow-md border-2 border-gray-50 rounded-lg'>
              <h2 className='text-xl mb-8'>Address</h2>

              <table className='w-full'>
                <tbody>
                  {/* Street */}
                  <tr>
                    <td className='py-3 align-top' width="30%">
                      <Label
                        name="address.street"
                      >
                        Street Address
                      </Label>
                    </td>
                    <td>
                      <Input
                        inline={true}
                        type='text'
                        readOnly={isReadOnly}
                        placeholder='Enter Street Address'
                        autoComplete='address'
                        {...register('address.street')}
                      />
                    </td>
                  </tr>

                  {/* Street */}
                  <tr>
                    <td className='py-3 align-top'>
                      <Label
                        name="address.city"
                      >
                        City
                      </Label>
                    </td>
                    <td>
                      <Input
                        inline={true}
                        type='text'
                        placeholder='Enter City'
                        autoComplete='city'
                        readOnly={isReadOnly}
                        {...register('address.city')}
                      />

                    </td>
                  </tr>

                   {/* State */}
                   <tr>
                    <td className='py-3 align-top'>
                      <Label
                        name="address.state"
                      >
                        State
                      </Label>
                    </td>
                    <td>
                      <Controller
                        name='address.state'
                        control={control}
                        render={({ field }) => (
                          <Autocomplete
                            readOnly={isReadOnly}
                            value={field.value || ''}
                            onChange={value => field.onChange(value)}
                            options={states}
                            placeholder='Choose State'
                          />
                        )}
                      />
                    </td>
                  </tr>

                  {/* Zip */}
                  <tr>
                    <td className='py-3 align-top'>
                      <Label
                        name="address.zip"
                      >
                        Zip Code
                      </Label>
                    </td>
                    <td>
                      <Input
                        inline
                        background='white'
                        placeholder='Enter Zip Code'
                        readOnly={isReadOnly}
                        {...register('address.zip', {
                          pattern: {
                            value: /^(?!00000)(\d{5})(?!-0000)(-\d{4})?$/,
                            message: 'Invalid Zip code',
                          },
                        })}
                        error={errors.address?.zip?.message}
                      />
                    </td>
                  </tr>

                  {/* Affiliated Clinics */}
                  <tr>
                    <td className='py-3 align-top'>
                      <Label
                        name="clinicIds"
                      >
                        Affiliated Clinics
                      </Label>
                    </td>
                    <td>
                      <div className='overflow-ellipsis'>

                        <Controller
                          name='clinicIds'
                          rules={{
                            validate: value => value && value.length > 0,
                            required: 'At least one clinic should be selected'
                          }}
                          control={control}
                          render={({ field }) => (
                            <Dropdown
                              required
                              className='h-12'
                              readOnly={isReadOnly}
                              value={field.value}
                              onChange={({ target: { value } }) => {
                                field.onChange(Array.isArray(value) ? value : [])
                              }}
                              options={
                                clinics ? clinics.map((clinic: ClinicDetail) => ({ label: clinic.name, value: clinic.id, disabled: clinicId === clinic.id })) : []
                              }
                              placeholder='Choose Affiliated Clinics'
                              multiple
                              error={errors.clinicIds?.message}
                              inline
                            />
                          )}
                        />

                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className='px-6 py-4 shadow-md border-2 border-gray-50 rounded-lg'>
              <h2 className='text-xl mb-8'>Demographics</h2>

              <table className='w-full'>
                <tbody>
                  {/* Race */}
                  <tr>
                    <td className='py-3 align-top' width="30%">
                      <Label
                        name="race"
                      >
                        {fieldLabel('race', 'Race')}*
                      </Label>
                    </td>
                    <td>
                      <Controller
                        name='race'
                        rules={{ required: 'Select a Race' }}
                        control={control}
                        render={({ field }) => (
                          <Dropdown
                            required
                            readOnly={isReadOnly}
                            value={field.value}
                            className='h-12'
                            background='white'
                            placeholder='Choose Race'
                            onChange={(event) =>
                              event?.target?.value && !Array.isArray(event?.target?.value) && field.onChange(event.target.value)
                            }
                            options={fieldValues('race', races)}
                            error={errors.race?.message}
                          />
                        )}
                      />

                    </td>
                  </tr>

                  {/* Gender */}
                  <tr>
                    <td className='py-3 align-top' width="30%">
                      <Label
                        name="gender"
                      >
                        {fieldLabel('gender', 'Gender')}*
                      </Label>
                    </td>
                    <td>
                      <Controller
                        name='gender'
                        rules={{ required: 'Select a Gender' }}
                        control={control}
                        render={({ field }) => (
                          <Dropdown
                            required
                            readOnly={isReadOnly}
                            value={field.value}
                            className='h-12'
                            background='white'
                            placeholder='Choose Gender'
                            onChange={(event) => {
                              event?.target?.value && !Array.isArray(event?.target?.value) && field.onChange(event.target.value)
                            }}
                            options={fieldValues('gender', genders)}
                            error={errors.gender?.message}
                          />
                        )}
                      />

                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className='px-6 py-4 shadow-md border-2 border-gray-50 rounded-lg'>
              <h2 className='text-xl mb-8'>Emergency Contact Info</h2>

              <table className='w-full'>
                <tbody>
                  {/* First Name */}
                  <tr>
                    <td className='py-3 align-top'>
                      <Label
                        name="emergencyContact.firstName"
                      >
                        First Name
                      </Label>
                    </td>
                    <td>
                      <Input
                        inline={true}
                        readOnly={isReadOnly}
                        type='text'
                        placeholder='Enter First Name'
                        autoComplete='first-name'
                        {...register('emergencyContact.firstName')}
                      />

                    </td>
                  </tr>

                  {/* Last Name */}
                  <tr>
                    <td className='py-3 align-top'>
                      <Label
                        name="emergencyContact.lastName"
                      >
                        Last Name
                      </Label>
                    </td>
                    <td>
                      <Input
                        inline={true}
                        readOnly={isReadOnly}
                        type='text'
                        placeholder='Enter Last Name'
                        autoComplete='last-name'
                        {...register('emergencyContact.lastName')}
                      />
                    </td>
                  </tr>

                  {/* Phone */}
                  <tr>
                    <td className='py-3 align-top'>
                      <Label
                        name="emergencyContact.phone"
                      >
                        Phone
                      </Label>
                    </td>
                    <td>
                      <Controller
                        name='emergencyContact.phone'
                        control={control}
                        rules={{
                          validate: (value) => {
                            if (isEmpty(value)) {
                              return true
                            }

                            return validatePhone(value)
                          },
                          required: false
                        }}
                        render={({ field }) => (
                          <InputPhone
                            inline={true}
                            type='text'
                            placeholder='Enter phone number (e.g., 123 456 7890)'
                            readOnly={isReadOnly}
                            value={getValues().emergencyContact?.phone || ''}
                            onChange={(value: string) => {
                              field.onChange(value)
                            }}
                            error={errors?.emergencyContact?.phone?.message}
                          />
                        )}
                      />
                    </td>
                  </tr>

                  {/* Relationship */}
                  <tr>
                    <td className='py-3 align-top'>
                      <Label
                        name="emergencyContact.relationship"
                      >
                        Relationship
                      </Label>
                    </td>
                    <td>
                      <Input
                        inline={true}
                        readOnly={isReadOnly}
                        type='text'
                        placeholder='Enter Relationship'
                        autoComplete='relationship'
                        {...register('emergencyContact.relationship')}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className='flex justify-end'>
            <Button disabled={!isDirty || loading} className='absolute left-[-2000px]' type='submit'>
              Save Patient
            </Button>
          </div>
        </form>

        <div className='flex'>
          <div
            className={clsx('text-center flex-1 border-white border-b p-2 rounded-tl-lg rounded-tr-lg', {
              'bg-light-blue font-medium': selectedTab === 'previous'
            })}
            role='button'
            onClick={() => setSelectedTab('previous')}
          >
            Previous
          </div>
          <div
            role='button'
            className={clsx('text-center flex-1 border-white border-b p-2 rounded-tl-lg rounded-tr-lg', {
              'bg-light-blue font-medium': selectedTab === 'cancelled'
            })}
            onClick={() => setSelectedTab('cancelled')}
          >
            Canceled
          </div>
        </div>

        {/* Prev Appointments */}
        {isEditMode && selectedTab === 'previous' && (
          <div ref={prevSessionsSectionRef}>
            <PreviousAppointments patientId={patient.id || ''} />
          </div>
        )}

        {/* Cancelled Appointments */}
        {isEditMode && selectedTab === 'cancelled' && (
          <div>
            <CancelledAppointments patientId={patient.id || ''} />
          </div>
        )}
      </div>
    </div>
  )
}

export const PatientFormScreen: React.FC<Props> = ({ isNewPatient }) => {
  const { id } = useParams()
  const { clinicId, enableLocalEmr } = useAuth()
  const { data, loading, error, mutate } = usePatients({ id }, {}, isNewPatient)
  const patient = data as PatientOutputDTO

  useTitle(isNewPatient ? 'Create patient' : `Edit Patient - ${patient?.firstName} ${patient?.lastName}`)

  const onSave = () => {
    mutate()
  }

  if (isNewPatient) {
    return (
      <PatientForm patient={{
        ...defaultPatient,
        clinicIds: clinicId ? [clinicId] : []
      }} onSave={onSave} />
    )
  }

  if (loading) {
    return <div>
      <div className='loading loading-spinner loading-sm' />
    </div>
  }

  if (error) {
    return (
      <div>
        <span className='text-2xl font-medium mr-8'>
          Patient not found
        </span>
      </div>
    )
  }

  if (patient) {
    const isReadOnly = patient.isReadOnly || !enableLocalEmr

    return (
      <PatientForm
        isReadOnly={isReadOnly}
        patient={patient}
        isEditMode={!isNewPatient}
        onSave={onSave}
      />
    )
  }
}
